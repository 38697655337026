import { params } from '../structure/constants';
import {initializeMap, clearMap, locationFound, geolocateMapButton} from '../modules/map';
import { manageGeoMarkers } from '../modules/map-popup';
import L from '../lib/leaflet.js';
import {MarkerClusterGroup, LayerGroup} from '../lib/leaflet.markercluster.js';
import { openTitle } from '../structure/home';

import  { ButtonDepartureArrival } from "journey_bundle/modules/buttonDepartureArrival/builder";

/*
 * Initialize map
 */
export const desktopBreakPoint = 600;

/*
 * Catch parameters from PnPCoreBundle/.../common_parameters.yml
 */
export var parameters = {
    urlImages : '/bundles/canaltppnpcore/images/',
    inputFromId : '#search_from_autocomplete',
    inputToId : '#search_to_autocomplete',
    longitude: window.Kisio?.map_config.longitude,
    latitude: window.Kisio?.map_config.latitude,
    longitude_decalage_home: window.Kisio?.map_config?.longitude_decalage_home ? window.Kisio?.map_config?.longitude_decalage_home : 0.1,
    zoom: window.Kisio?.map_config.zoom,
    max_zoom: window.Kisio?.map_config.max_zoom,
    zoom_control: window.Kisio?.map_config.zoom_control,
    icon_url: window.Kisio?.map_config.icon_url,
    icon_size: window.Kisio?.map_config.icon_size,
    popup_anchor: window.Kisio?.map_config.popup_anchor,
    tile_url: window.Kisio?.osm_map_tile_urls,
    tile_url_plan: window.Kisio?.osm_map_tile_urls,
    tile_url_satellite: window.Kisio?.osm_map_satellite,
    searchAutocompleteClass: '.journey-search-autocomplete',
    L,
    MarkerClusterGroup,
    LayerGroup,
};

window.Kisio = window.Kisio || {};
window.leaflet = window.leaflet || {};

export function displayMap() {
    if (JKisio('#mapid').is(':visible')) {
        if(!window.leaflet.map){
            window.leaflet.map = initializeMap(parameters);
            window.boardWidth = (JKisio('#board').length > 0) ? JKisio('#board').outerWidth() + JKisio('#board').offset().left : 0;
        }
        /*
         * Map construction
         */
        JKisio(function(){
            /*
             * Baseline integration
             */
            let urlVue = parameters.tile_url;
            let vueClassName = '.vue[data-type="satellite"]';

            try {
                if (typeof sessionStorage.getItem('vueUrl') == 'undefined') {
                    let vueStorage = sessionStorage.getItem('vueUrl');
                    if (vueStorage == parameters.tile_url_satellite) {
                        vueClassName = '.vue[data-type="plan"]';
                    }
                    urlVue = parameters.tile_url_plan;
                }
            }catch (e) {
                if (e.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
                    console.log("Private here");
                }
            }
            parameters.L.tileLayer(urlVue, {
                maxZoom: parameters.max_zoom,
                attribution: Translator.trans('canaltp_pnpcore.map.attribution') + Translator.trans('canaltp_pnpcore.map.attribution_url'),
                id: 'mapbox.streets'
            }).addTo(window.leaflet.map);
            JKisio(vueClassName).removeClass('d-none').siblings('.vue').addClass('d-none');

            /*
             * Position of zoom buttons
             */
            L.control.zoom({position: 'topright'}).addTo(window.leaflet.map);
            let url;
            /**
             * After initializing basic conf for map (zoom & attribution content), we disable tabindex for them for better accessibility behavior (excluding objects map-related)
             */
            window.leaflet.map.whenReady(function () {
                JKisio('.leaflet-control-zoom a').attr('tabindex', '-1');
                JKisio('.leaflet-control-attribution, .leaflet-control-attribution a').attr('tabindex', '-1');

                Kisio.GeolocateOnFirstLoad(L);

                /*
                 * Add geolocation button on map
                 */
                if (Kisio.geolocate_on_first_load == '1' && Kisio.user_consent == true && !JKisio('.kisio-global-popup').is(':visible')) {
                    let geolocate_cookie_refused = document.cookie.match(/nws_geolocation_refused=([^;]+)/);
                    let geolocate_cookie_accepted = document.cookie.match(/nws_geolocation_accepted=([^;]+)/);

                    if ( geolocate_cookie_accepted === null && (geolocate_cookie_refused === null || typeof geolocate_cookie_refused !== "undefined")) {
                        geolocateMapButton(L);
                    }
                }else if(Kisio.geolocate_on_first_load == '1' && Kisio.user_consent == true && JKisio('.kisio-global-popup').is(':visible')) {
                    JKisio('.leaflet-control-locate').hide();
                }
            });

            /*
             * Load geojson file if activate
             */
            url = window.location.search;
            if (typeof Kisio.geojson_file !== 'undefined' && url === '' && Kisio.geojson_file != '') {
                JKisio(function () {
                    JKisio.getJSON(Kisio.geojson_file, function (data) {
                        // L.geoJson(data).addTo(window.leaflet.map);
                        Kisio.layerGroup.home.homeLayers.addLayer(L.geoJson(data));
                        Kisio.layerGroup.home.homeLayers.addTo(window.leaflet.map);
                    });
                });
                JKisio(document).on('click', '.search_from_autocomplete .ui-menu-item a, .search_to_autocomplete .ui-menu-item a', function () {
                    clearMap(true);
                });
            }

            /*
             * Geolocation in from and to inputs
             */
            JKisio(document).on('keyup', '.locate-position', function(e){
                if (e.keyCode == 32 || e.keyCode == 13) {
                    JKisio(this).trigger('click');
                }
            });
            JKisio(document).on('click','.locate-position', function(e){
                navigator.geolocation.getCurrentPosition(
                    function(e) { document.dispatchEvent(new CustomEvent("geoloGranted", {detail: e})); },
                    function(positionError) {
                        console.log(positionError)
                        if(positionError.code == 1) {
                            window.Kisio.autocomplete_config.geolocation = 'denied';
                        }else{
                            window.Kisio.autocomplete_config.geolocation = 'granted';
                        }
                    },
                    // {timeout: 0}
                );
                if (JKisio('#proximity_search_uri').length > 0) { 
                    window.leaflet.map.locate({setView: true, maxZoom: Kisio.map_config.max_zoom});
                } else {
                    window.leaflet.map.locate({setView: true, maxZoom: 15});
                }

                let fieldType = JKisio(this).attr('data-type');

                window.leaflet.map.on('locationfound', locationFound, this);
                JKisio(`#search-${fieldType}-autocomplete-clear`).show();
                Kisio.user_consent_position = true;

                //If there is results, show new search section
                if (JKisio(params.classContent).is(':visible') || JKisio(params.classContent).length > 0) {
                    JKisio(params.classContent).hide();
                    JKisio(params.formNewSearchId).show();
                    JKisio(params.searchBoardModes).show();
                }
            });


            /*
             * Catch on click event on map and launch popup, except on Standalone Schedule
             */
            if (JKisio('body').outerWidth(true) >= params.desktopBreakPoint) {
                url = decodeURIComponent(window.location);
                let regex = /standalone\/schedule/;
                if (url.match(regex) === null) {
                    window.leaflet.map.on('click', function (e) {
                        Kisio.MapPopup(e, window.leaflet.map);
                    });
                }
            }

            // add marker when data-coord are present
            JKisio(parameters.searchAutocompleteClass).each(function (e) {
                let child = JKisio(this).children('div[data-coord]');
                if (child.length > 0) {
                    let coord = child.attr('data-coord').split(',');
                    let id = child.children().attr('id').split('_');
                    manageGeoMarkers(id[1], '', coord);
                }
            });
        });

        // Manage Vue Type (Satellitte / Plan) on click
        JKisio(document).on('click','.vue', function(e){
            let vueType = JKisio(this).data('type'),
                mapsUrl = vueType=='plan'? parameters.tile_url_plan:parameters.tile_url_satellite;
            try {
                sessionStorage.setItem('vueUrl', mapsUrl);
            }catch (e) {
                if (e.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
                    console.log("Private here");
                }
            }
            L.tileLayer(mapsUrl, {
                maxZoom: parameters.max_zoom,
                attribution: Translator.trans('canaltp_pnpcore.map.attribution') + Translator.trans('canaltp_pnpcore.map.attribution_url'),
                id: 'mapbox.streets'
            }).addTo(window.leaflet.map);
            JKisio(this).addClass('d-none').siblings('.vue').removeClass('d-none');
        });
    }
}

/*
 *  function to create popup
 */
Kisio.MapPopup = function(e, map) {
    let lat = e.latlng.lat, lng = e.latlng.lng, popup = L.popup(), latlng = e.latlng;
    let type_product = (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '';
    JKisio.ajax({
        url: Routing.generate('coords', { coords: lng+';'+lat, type_product: type_product }),
        dataType: "json",
        success: function(result) {
            let address = result.label, header_icon = 'ikisio-address', addressLngLat = lng.toFixed(6) +';'+ lat.toFixed(6);
            if (address == null) {
                address = addressLngLat;
                header_icon = 'ikisio-arrival';
            }
            let addressLatLng = lat.toFixed(6) +';'+ lng.toFixed(6);
            let query = '';
            if (JKisio('#ctp-proximityPoiForm').length > 0) {
                let queryInit = decodeURIComponent(JKisio('#ctp-proximityPoiForm').serialize());
                let regex = /proximity_search\[uri\]\[autocomplete\]=(.+)&proximity_search\[uri\]\[autocomplete-hidden\]=(.+)/gm;
                let subst = 'proximity_search[uri][autocomplete]=' + address + '&proximity_search[uri][autocomplete-hidden]=' + addressLngLat;
                query = queryInit.replace(regex, subst);
                query += '&proximity_search[coords]=' + addressLatLng;
            } else {
                let proximityParams = {
                    'proximity_search[uri][autocomplete-hidden]': addressLngLat,
                    'proximity_search[uri][autocomplete]': address,
                    'proximity_search[coords]': addressLatLng
                };
                query = JKisio.param(proximityParams);
            }
            let href = Routing.generate('proximity_form_result', { type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '' }) + '?' + query;
            
            let buttonPopUp = new ButtonDepartureArrival({
                lat: lat,
                lng: lng,
                address: address
            }).buildHtml();

            if (type_product === "full" && window.Kisio.allowSection.proximity) {
                buttonPopUp += '<a id="iKisio-link" href="' + href + '"><button id="popup-proximity-btn" class="ikisio ikisio-proximity"> ' +
                '<span>' + Translator.trans('proximity.form.poi.title') + '</span></button></a>';
            }
            
            if (type_product === '' || type_product === 'standalone') {
                if (JKisio('#journey_content').length > 0 || JKisio('#result_content').length > 0) {
                    buttonPopUp = new ButtonDepartureArrival({
                        lat: lat,
                        lng: lng,
                        address: address
                    }).buildHtml();
                } else if (JKisio('.proximity-standalone').length > 0) {
                    buttonPopUp = '<a href="' + href + '"><button id="popup-proximity-btn" class="ikisio ikisio-proximity"> ' +
                        '<span>' + Translator.trans('proximity.form.poi.title') + '</span></button></a>';
                } else {
                    buttonPopUp = '';
                }
            }

            let customPopup = '<div id="leaflet-popup">' +
                '<div id="leaflet-popup-header">' +
                '   <div class="ikisio '+header_icon+'"></div><div>'+address+'</div>' +
                '</div>' +
                '<div id="leaflet-popup-content" class="clearfix">' + buttonPopUp + '</div></div>';

            popup.setLatLng(e.latlng).setContent(customPopup).openOn(map);

            JKisio("#popup-departure-btn, #popup-arrival-btn").on('click', function (e) {
                clearMap(true);
                let fieldType = JKisio(this).attr('data-type');
                let params = {
                    fieldType: fieldType,
                    address: address,
                    latlng: latlng,
                    type: 'map',
                    autocompleteHiddenValue: ''
                };
                manageClickPopUpJourneyButtons(params, map);
            });

            ManagePopup(e.containerPoint, map);
        }
    });
};

/*
 * function to center Popup
 */
export function ManagePopup(point, map) {
    let popupWidth = JKisio('#leaflet-popup').outerWidth();
    let layerPointX = window.boardWidth + popupWidth/1.8;
    if (point.x < layerPointX ){
        let newPointX = point.x - layerPointX;
        map.panBy(new L.Point( newPointX , 0));
        let popupHeight = JKisio('#leaflet-popup').outerHeight();
        if (point.y < popupHeight)
            map.panBy(new L.Point( newPointX , point.y - popupHeight -10 ));
    }
};

export function mapPopupConfig() {
    // const boardWidth = window.boardWidth;
    const boardWidth = (JKisio('#board').length > 0) ? JKisio('#board').outerWidth() + JKisio('#board').offset().left : 0;

    if (JKisio('body').outerWidth(true) < desktopBreakPoint) {
        return {
            paddingTopLeft: L.point(boardWidth - 250, -250),
            paddingBottomRight: L.point(0, 0),
            maxZoom: 14
        }
    }

    return {
        paddingTopLeft: L.point(boardWidth + 30, 32),
        paddingBottomRight: L.point(0, 32)
    }
};

/*
 * function to generate custom marker
 */
Kisio.MapPopup.getCustomerMarker = function(fieldType, latlng){
    const origin = L.icon({
        iconUrl: parameters.urlImages + 'map-marker-' + fieldType + '.svg',
        iconSize: [25, 57],
        iconAnchor: [13.5, 46.5],

    });
    const markerMap = new L.marker(latlng, {title: fieldType, icon: origin, layerType: 'journey', layer_section: ['journey'], keyboard: false});
    markerMap.bindTooltip(Translator.trans('popup.links.' + fieldType + '.label'), {
        permanent: true,
        className: "label-map-marker-" + fieldType,
        direction: 'top',
        layerType: 'journey',
        layer_section: ['journey'],
        marker_name: `search_${fieldType}_autocomplete`
    });
    return markerMap;
};

Kisio.GeolocateOnFirstLoad = function (L) {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(location.search);
    let geolocate_cookie_refused = document.cookie.match(/nws_geolocation_refused=([^;]+)/);
    let geolocate_cookie_accepted = document.cookie.match(/nws_geolocation_accepted=([^;]+)/);

    const now = new Date();
    now.setTime(now.getTime()+(7*24*60*60*1000));

    if ( (geolocate_cookie_refused === null || (typeof geolocate_cookie_refused !== "undefined" && geolocate_cookie_refused[1] < now))
        && (geolocate_cookie_accepted === null || (typeof geolocate_cookie_accepted !== "undefined" && geolocate_cookie_accepted[1] < now))
        && (queryString == '' || urlParams.get('user_consent') == 'true') && Kisio.geolocate_on_first_load == '1' && Kisio.user_consent == true) {

        Kisio.getPopupGeolocate(L);
    }
}

Kisio.getPopupGeolocate = function (L) {
    if(JKisio('.kisio-global-popup-full').length !== 0) {
        return;
    }

    let popup_full = JKisio('<div/>').addClass('kisio-global-popup-full');
    let global_popup = JKisio('<div/>').addClass('kisio-global-popup');
    let popup_header = JKisio('<div/>').addClass('kisio-popup-header');
    let close_icon = JKisio('<i/>').addClass('ikisio ikisio-close').attr('id', 'kisio-popup-close');
    popup_header.append(Translator.trans('canaltp_pnpcore.geolocate.title'));
    popup_header.append(close_icon);
    let popup_body = JKisio('<div/>').addClass('kisio-popup-body');
    let popup_footer = JKisio('<div/>').addClass('kisio-popup-footer');
    let body = '<div id="geolocate-first-step">' +
        ' <img id="geolocate-img" src="/bundles/canaltppnpcore/images/geolocate.png" /><br />' +
        Translator.trans('canaltp_pnpcore.geolocate.content') +
        '<div id="geolocate_buttons">' +
        '   <button className="ctp-button-primary" id="btn_refuse" type="submit" value="" title="" aria-label=""><span>'+ Translator.trans('canaltp_pnpcore.geolocate.refuse') +'</span></button>' +
        '   <button className="ctp-button-primary" id="btn_accept" type="submit" value="" title="" aria-label=""><span>'+ Translator.trans('canaltp_pnpcore.geolocate.accept') +'</span></button>' +
        '</div>' +
        '</div>' +
        '<div id="geolocate-second-step">' +
        Translator.trans('canaltp_pnpcore.geolocate.confirm') +
        '<div id="geolocate_buttons">' +
        '   <button className="ctp-button-primary" id="btn_no" type="submit" value="" title="" aria-label=""><span>'+ Translator.trans('canaltp_pnpcore.geolocate.no') +'</span></button>' +
        '   <button className="ctp-button-primary" id="btn_yes" type="submit" value="" title="" aria-label=""><span>'+ Translator.trans('canaltp_pnpcore.geolocate.yes') +'</span></button>' +
        '</div>' +
        '</div>';
    popup_body.append(body);
    global_popup.append(popup_header)
        .append(popup_body)
        .append(popup_footer);
    popup_full.append(global_popup);
    popup_full.appendTo('body');

    //If user click on the outside of the popup
    JKisio(document).click(function (e) {
        const targetClass = JKisio(e.target).attr('class');
        if (targetClass && targetClass === 'kisio-global-popup-full' ) {
            JKisio('#geolocate-first-step').hide();
            popup_header.html('');
            JKisio('#geolocate-second-step').show();
        }
    })

    //If user want to close or refuse the geolocation
    JKisio('#kisio-popup-close, #btn_refuse').on('click', function (e) {
        JKisio('#geolocate-first-step').hide();
        popup_header.html('');
        JKisio('#geolocate-second-step').show();
    });

    //If user refuse and confirm the geolocation, a cookie is created
    JKisio('#btn_yes').click(function (e) {
        JKisio('.kisio-global-popup-full').remove();
        const date = new Date();
        date.setTime(date.getTime()+(7*24*60*60*1000));
        document.cookie = "nws_geolocation_refused="+date.toUTCString()+"; expires="+date.toUTCString()+"; path=/; SameSite=None; Secure";
        geolocateMapButton(L);
    });

    //If user refuse and not confirm the geolocation, it goes back to the first popup
    JKisio('#btn_no').click(function (e) {
        JKisio('#geolocate-second-step').hide();
        popup_header.html(Translator.trans('canaltp_pnpcore.geolocate.title'));
        popup_header.append(close_icon);
        JKisio('#geolocate-first-step').show();
        JKisio('#kisio-popup-close').on('click', function (e) {
            JKisio('#geolocate-first-step').hide();
            popup_header.html('');
            JKisio('#geolocate-second-step').show();
        });
    });

    //If user accept the geolocation,
    JKisio('#btn_accept').click(function (e) {
        JKisio('.leaflet-control-locate').hide();

        //If set, we destroy cookie nws_geolocation_refused
        let geolocate_cookie_refused = document.cookie.match(/nws_geolocation_refused=([^;]+)/);
        if ( typeof geolocate_cookie_refused !== "undefined") {
            let expires = new Date();
            expires.setTime(expires.getTime()+ (-1 * 24 * 60 * 60 * 1000));
            document.cookie = "nws_geolocation_refused=;expires="+expires.toUTCString()+"; path=/; SameSite=None; Secure";
        }

        const date = new Date();
        //One year
        date.setTime(date.getTime()+(365*24*60*60*1000));
        document.cookie = "nws_geolocation_accepted="+date.toUTCString()+"; expires="+date.toUTCString()+"; path=/; SameSite=None; Secure";
        window.leaflet.map.locate({setView: true, maxZoom: 15})
            .on('locationfound', function(e){
                JKisio('.kisio-global-popup-full').remove();
                let icon = L.icon({
                    iconUrl: parameters.urlImages + 'pin-geoloc.svg',
                    iconSize: parameters.icon_size
                });
                let coords = e.latlng.lng+';'+e.latlng.lat;
                // L.marker(e.latlng, {icon: icon, keyboard: false}).addTo(window.leaflet.map);
                Kisio.layerGroup.home.homeLayers.addLayer(L.marker(e.latlng, {icon: icon, keyboard: false}));
                Kisio.layerGroup.home.homeLayers.addTo(window.leaflet.map);
                
                JKisio('#search_from_autocomplete-hidden').val(coords);
            })
            .on('locationerror', function(e){
                console.log(e);
                JKisio('.kisio-global-popup-full').remove();
            });
    });
}